// Coordenadas da localização especificada
var latitude = -29.207893465941112;
var longitude = -51.52820105213349;

let iconWidthAndHeight = () => {
    return screen.width > 992 ? 113 : 60;
}

// Criando o mapa com base nas coordenadas especificadas
var map = L.map('mapa', {
    closePopupOnClick: false
}).setView([latitude, longitude], 14);

// Desabilitar o scroll do mouse
map.scrollWheelZoom.disable();

// Criando uma camada de mapa
var layer = new L.TileLayer(
   "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 16
   }
);
// Adicionando a camada ao mapa
map.addLayer(layer);

let customIcon = {
   iconUrl: $("#map-icon").val(),
   iconSize: [iconWidthAndHeight(), iconWidthAndHeight()],
   popupAnchor: [0, -40],
   setZIndex: 10,
}
let myIcon = L.icon(customIcon);

let iconOptions = {
   title: 'Projetarium',
   draggable: false,
   icon: myIcon,
}

// Criando o marcador
let marker = new L.Marker([latitude, longitude], iconOptions);
let address = $("#address").val();
marker.addTo(map);

// Bind do popup com opções
marker.bindPopup(`<p>
    <a href="https://maps.app.goo.gl/tK2QA9rX7eKCyu4Q6" target="_blank">
        ${address}
    </a>
</p>`, {
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
    keepInView: true
}).openPopup();

// prevent popup from closing
marker.on('click', function (e) {
    marker.openPopup();
});

const mapa = document.querySelector('#mapa');